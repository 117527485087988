import { contentUrl, pdfViewer } from '../api'

const url = `${contentUrl}/oncology`
const pdf = `${pdfViewer}${url}`

export const SWITCHED_ONCOLOGY = {
  name: 'SWITCHED_ONCOLOGY',
  title: 'SWITCHED_ONCOLOGY',
  mobileType: "mobile",
  content: [
    {
      menuTemplate: "SINGLE",
      menuName: "Film",
      content: {
        contentTemplate: "VIDEOFULLSCREEN",
        content: {
          src: `${url}/our_film.mp4`,
          poster: `${url}/poster_our_film.png`,
        },
      },
    },
    {
      menuTemplate: "SINGLE",
      menuName: "Website",
      content: {
        link: "https://www.switchedoncology.com/",
      },
    },
    {
      menuTemplate: "SINGLE",
      menuName: "White Paper",
      content: {
        contentTemplate: 'PDF',
        content: {
          pdfSrc: `${pdf}/White_Paper_18_The_Big_Communication.pdf`,
        },
      },
    },
    {
      menuTemplate: 'DROPDOWN',
      menuName: 'Articles',
      hideMobileArrows: true,
      subItems: [
        {
          subMenuName: "Let's change the conversation about cancer",
          contentTemplate: 'PDF',
          content: {
            pdfSrc: `${pdf}/Article_PME_Let's_change_the_conversation_about_cancer.pdf`,
          },
        },
        {
          subMenuName: 'Beyond caring',
          contentTemplate: 'PDF',
          content: {
            pdfSrc: `${pdf}/Article_PME_Beyond_caring.pdf`,
          },
        },
        {
          subMenuName: 'Cancer in the information age',
          contentTemplate: 'PDF',
          content: {
            pdfSrc: `${pdf}/Article_PME_Cancer_in_the_information_age.pdf`,
          },
        },
      ],
    },
    {
      menuTemplate: "SINGLE",
      menuName: "Case Study",
      content: {
        contentTemplate: 'PDF',
        content: {
          pdfSrc: `${pdf}/Case_Study_SWITCHED_ONcology.pdf`,
        },
      },
    },
  ],
}
