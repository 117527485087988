import { contentUrl } from '../api'

const url = `${contentUrl}/health_for_all`

export const HEALTH_FOR_ALL = {
  name: 'HEALTH_FOR_ALL',
  title: 'HEALTH_FOR_ALL',
  mobileType: "mobile",
  content: [
    {
      menuTemplate: "SINGLE",
      menuName: "Film",
      content: {
        contentTemplate: "VIDEOFULLSCREEN",
        content: {
          src: `${url}/campaign_film.mp4`,
          poster: `${url}/poster_campaign_film.png`,
        },
      },
    },
    {
      menuTemplate: "SINGLE",
      menuName: "Website",
      content: {
        contentTemplate: "",
        link: "https://nooneleftbehind.co.uk/"
      },
    },
    {
      menuTemplate: "SINGLE",
      menuName: "Podcast",
      content: {
        contentTemplate: "",
        link: "https://podcasts.apple.com/gb/podcast/health-for-all/id1571294629"
      },
    },
  ]
}
