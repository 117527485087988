import { contentUrl, pdfViewer } from '../api'

const url = `${contentUrl}/healthcare_heroes`
const pdf = `${pdfViewer}${url}`

export const HEALTHCARE_HEROES = {
  name: 'HEALTHCARE_HEROES',
  title: 'HEALTHCARE_HEROES',
  mobileType: "mobile",
  content: [
    {
      menuTemplate: "SINGLE",
      menuName: "Documentary",
      content: {
        contentTemplate: "VIDEOFULLSCREEN",
        content: {
          src: `${url}/documentary_film.mp4`,
          poster: `${url}/poster_documentary.png`,
        },
      },
    },
    {
      menuTemplate: "SINGLE",
      menuName: "Teaser Film",
      content: {
        contentTemplate: "VIDEOFULLSCREEN",
        content: {
          src: `${url}/teaser_film.mp4`,
          poster: `${url}/poster_teaser_film.png`,
        },
      },
    },
    {
      menuTemplate: "SINGLE",
      menuName: "Website",
      content: {
        link: "https://healthcare-heroes.com/",
      },
    },
    {
      menuTemplate: 'DROPDOWN',
      menuName: 'Heroes Spotlights',
      hideMobileArrows: true,
      subItems: [
        {
          subMenuName: 'Sarah',
          contentTemplate: 'PDF',
          content: {
            pdfSrc: `${pdf}/Healthcare_Heroes_Sarah.pdf`,
          }
        },
        {
          subMenuName: 'Marc',
          contentTemplate: 'PDF',
          content: {
            pdfSrc: `${pdf}/Healthcare_Heroes_Marc.pdf`,
          },
        },
        {
          subMenuName: 'Olive',
          contentTemplate: 'PDF',
          content: {
            pdfSrc: `${pdf}/Healthcare_Heroes_Olive.pdf`,
          },
        },
        {
          subMenuName: 'Steve',
          contentTemplate: 'PDF',
          content: {
            pdfSrc: `${pdf}/Healthcare_Heroes_Steve.pdf`,
          },
        },
      ],
    },
    {
      menuTemplate: "SINGLE",
      menuName: "Case Study",
      content: {
        contentTemplate: 'PDF',
        content: {
          pdfSrc: `${pdf}/Case_Study_PMLive_Healthcare_Heroes.pdf`,
        },
      },
    },
  ],
}
