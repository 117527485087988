//@ts-nocheck
import React, { useState  } from 'react'
import App from './App'
import { styled } from '@lxlabs/vc-components/dist/index'

const models = [
  {
    name: 'Click here to enter the Havas Lynx Group<br />Virtual Booth Experience 1',
    path: 'model1',
    img: "https://s3.eu-west-2.amazonaws.com/uploads.vc-lite.hlxcongress.digital/assets/lite-1.png"
  },
  {
    name: 'Click here to enter the Havas Lynx Group<br />Virtual Booth Experience 2',
    path: 'model2',
    img: "https://s3.eu-west-2.amazonaws.com/uploads.vc-lite.hlxcongress.digital/assets/lite-2.png"
  }
]

export const LandingPage = () => {
  
  const [activeModel, setActiveModel] = useState(null);

  const update = (selectedModel) => {
    import(`./config/${selectedModel}`)
      .then(data => 
        setActiveModel(data)
      );
  }

  return (
    <>
      {!activeModel && 
        <>
          <ModelOverlay />
          <ModelLogo src="https://s3.eu-west-2.amazonaws.com/uploads.vc-lite.hlxcongress.digital/assets/havas-logo.png" alt=""/>
          <ModelContainer>
            {models.map((model, i) => {
              return (
                <div>
                  <ModelImage src={model.img} alt=""/>
                  <ModelButton key={i} onClick={() => update(model.path)} dangerouslySetInnerHTML={{ __html: model.name }}></ModelButton>
                </div>
              )
            })}
          </ModelContainer>
        </>
      }
      {activeModel && 
      <>
        <CloseButton onClick={() => setActiveModel(null)}>Go back to landing page</CloseButton>
        <App sceneConfig={activeModel.default} />
      </>
      
      }
    </>
  )
}

const ModelOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: black;
  opacity: 0;
  animation: overlay 1.5s;
  @keyframes overlay {
    0% { opacity: 1; height: 100%; width: 100%; }
    50% { opacity: 1 }
    99% { height: 100%; width: 100%; }
    100% { opacity: 0; height: 0; width: 0; }
  }
`

const ModelContainer = styled.div`
  text-align: center;
  height: 100vh;
  
  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`

const ModelLogo = styled.img`
  position: absolute;
  left: 10%;
  top: 50px;
`

const ModelImage = styled.img`
  margin: 0 auto;
  width: 60%;
  min-height: 200px;
  min-width: 400px;
`

const ModelButton = styled.div`
  background: #2e2940;
  border-radius: 8px;
  color: white;
  padding: 8px 16px;
  margin: 16px auto;
  width: 60%;
  :hover {
    background: #ff5454;
    cursor: pointer;
    transition: background 0.5s;
  }
`

const CloseButton = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  background: #ff5454;
  color: white;
  padding: 8px;
  opacity: 0.2;
  border-radius: 8px;
  &:hover {
    opacity: 1;
    transition: opacity 0.5s;
    cursor: pointer;
  }
`