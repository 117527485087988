import { contentUrl, pdfViewer } from '../api'

const url = `${contentUrl}/patient_centricity_on_trial`
const pdf = `${pdfViewer}${url}`

export const PATIENT_CENTRICITY_ON_TRIAL = {
  name: 'PATIENT_CENTRICITY_ON_TRIAL',
  title: 'PATIENT_CENTRICITY_ON_TRIAL',
  mobileType: "mobile",
  content: [
    {
      menuTemplate: "SINGLE",
      menuName: "Film",
      content: {
        contentTemplate: "VIDEOFULLSCREEN",
        content: {
          src: `${url}/our_film.mp4`,
          poster: `${url}/poster_our_film.png`,
        },
      },
    },
    {
      menuTemplate: "SINGLE",
      menuName: "Website",
      content: {
        contentTemplate: "",
        link: "https://patientcentricityontrial.com"
      },
    },
    {
      menuTemplate: "SINGLE",
      menuName: "White Paper",
      content: {
        contentTemplate: 'PDF',
        content: {
          pdfSrc: `${pdf}/White_Paper_19_Patient_Centricity_on_Trial.pdf`,
        },
      },
    },
    {
      menuTemplate: "SINGLE",
      menuName: "Case Study",
      content: {
        contentTemplate: 'PDF',
        content: {
          pdfSrc: `${pdf}/Case_Study_Patient_Centricity_on_Trial.pdf`,
        },
      },
    },
  ],
}
