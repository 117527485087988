const menu = {
    loaded: true,
    menuItems: [
      {
        instance: 'default',
        title: '<b>Home</b>',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary',
      },
      {
        instance: 'HEALTH_FOR_ALL',
        title: '<b>Health For All</b>',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary',
      },
      {
        instance: 'MEDIA_MEANS_BUSINESS',
        title: '<b>Media Means Business</b>',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary',
      },
      {
        instance: 'PATIENT_CENTRICITY_ON_TRIAL',
        title: '<b>Patient Centricity on Trial</b>',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary',
      },
      {
        instance: 'INVISIBLE_ARMY',
        title: '<b>The Invisible Army</b>',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary',
      },
      {
        instance: 'SWITCHED_ONCOLOGY',
        title: '<b>SWITCHED ONcology</b>',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary'
      },
      {
        instance: 'HEALTHCARE_HEROES',
        title: '<b>Healthcare Heroes</b>',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary',
      },
    ],
  };

export default menu;