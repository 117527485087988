export * from './PATIENT_CENTRICITY_ON_TRIAL'
export * from './MEDIA_MEANS_BUSINESS';
export * from './SWITCHED_ONCOLOGY';
export * from './INVISIBLE_ARMY';
export * from './HEALTHCARE_HEROES';
export * from './HEALTH_FOR_ALL';

export enum ContentTypes {
  PATIENT_CENTRICITY_ON_TRIAL = 'PATIENT_CENTRICITY_ON_TRIAL',
  MEDIA_MEANS_BUSINESS = 'MEDIA_MEANS_BUSINESS',
  SWITCHED_ONCOLOGY = 'SWITCHED_ONCOLOGY',
  INVISIBLE_ARMY = 'INVISIBLE_ARMY',
  HEALTH_FOR_ALL = 'HEALTH_FOR_ALL',
  HEALTHCARE_HEROES = 'HEALTHCARE_HEROES'
}

export const contentNavigation = [
  { title: 'PATIENT_CENTRICITY_ON_TRIAL', instance: ContentTypes.PATIENT_CENTRICITY_ON_TRIAL },
  { title: 'MEDIA_MEANS_BUSINESS', instance: ContentTypes.MEDIA_MEANS_BUSINESS },
  { title: 'SWITCHED_ONCOLOGY', instance: ContentTypes.SWITCHED_ONCOLOGY },
  { title: 'INVISIBLE_ARMY', instance: ContentTypes.INVISIBLE_ARMY },
  { title: 'HEALTHCARE_HEROES', instance: ContentTypes.HEALTHCARE_HEROES },
  { title: 'HEALTH_FOR_ALL', instance: ContentTypes.HEALTH_FOR_ALL },
]
