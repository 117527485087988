import { contentUrl, pdfViewer } from '../api'

const url = `${contentUrl}/the_invisible_army`
const pdf = `${pdfViewer}${url}`

export const INVISIBLE_ARMY = {
  name: 'INVISIBLE_ARMY',
  title: 'INVISIBLE_ARMY',
  mobileType: "mobile",
  content: [
    {
      menuTemplate: "SINGLE",
      menuName: "Film",
      content: {
        contentTemplate: "VIDEOFULLSCREEN",
        content: {
          src: `${url}/our_film.mp4`,
          poster:  `${url}/poster_our_film.png`,
        },
      },
    },
    {
      menuTemplate: "SINGLE",
      menuName: "Website",
      content: {
        link: "https://www.invisible-army.com/",
      },
    },
    {
      menuTemplate: "SINGLE",
      menuName: "White Paper",
      content: {
        contentTemplate: 'PDF',
        content: {
          pdfSrc: `${pdf}/White_Paper_17_In_Search_of_the_Invisible_Army.pdf`,
        },
      },
    },
    {
      menuTemplate: "SINGLE",
      menuName: "Podcast: Episode 1",
      content: {
        contentTemplate: "",
        link: "https://podcasts.apple.com/gb/podcast/finding-guidance/id1316139715?i=1000523917391"
      },
    },
    {
      menuTemplate: "SINGLE",
      menuName: "Podcast: Episode 2",
      content: {
        contentTemplate: "",
        link: "https://podcasts.apple.com/gb/podcast/finding-friends/id1316139715?i=1000523917539"
      },
    },
    {
      menuTemplate: "SINGLE",
      menuName: "Podcast: Episode 3",
      content: {
        contentTemplate: "",
        link: "https://podcasts.apple.com/gb/podcast/finding-me/id1316139715?i=1000523917565"
      },
    },
    {
      menuTemplate: "SINGLE",
      menuName: "Case Study",
      content: {
        contentTemplate: 'PDF',
        content: {
          pdfSrc: `${pdf}/Case_Study_In_Search_of_the_Invisible_Army.pdf`,
        },
      },
    },
  ],
}
