import { contentUrl, pdfViewer } from '../api'

const url = `${contentUrl}/media_means_business`
const pdf = `${pdfViewer}${url}`

export const MEDIA_MEANS_BUSINESS = {
  name: 'MEDIA_MEANS_BUSINESS',
  title: 'MEDIA_MEANS_BUSINESS',
  mobileType: "mobile",
  content: [
    {
      menuTemplate: "SINGLE",
      menuName: "Film",
      content: {
        contentTemplate: "VIDEOFULLSCREEN",
        content: {
          src: `${url}/our_film.mp4`,
          poster: `${url}/poster_our_film.png`,
        },
      },
    },
    {
      menuTemplate: "SINGLE",
      menuName: "Website",
      content: {
        link: "https://havaslynx.com/thought-leadership/media-means-business/",
      },
    },
    {
      menuTemplate: "SINGLE",
      menuName: "White Paper",
      content: {
        contentTemplate: 'PDF',
        content: {
          pdfSrc: `${pdf}/White_Paper_20_Media_Means_Business.pdf`,
        },
      },
    },
    {
      menuTemplate: "SINGLE",
      menuName: "Case Study",
      content: {
        contentTemplate: 'PDF',
        content: {
          pdfSrc: `${pdf}/Case_Study_Media_Means_Business.pdf`,
        },
      },
    },
  ],
}
